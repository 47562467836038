










































































































































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { ImageSourceSet } from 'client-website-ts-library/types';

@Component({
  components: {
  },
})
export default class TenantResources extends Mixins(View) {
  public image1: ImageSourceSet = { source: '/assets/images/photos/tenant-resources-appyling', formats: ['webp', 'jpg'], fallback: 'jpg' };

  public image2: ImageSourceSet = { source: '/assets/images/photos/tenant-resources-ending', formats: ['webp', 'jpg'], fallback: 'jpg' };

  public image3: ImageSourceSet = { source: '/assets/images/photos/tenant-resources-during', formats: ['webp', 'jpg'], fallback: 'jpg' };

  public image4: ImageSourceSet = { source: '/assets/images/photos/tenant-resources-addtional', formats: ['webp', 'jpg'], fallback: 'jpg' };
}
